import { polygonAmoy } from "../utils/viemCustomChains";
import { config } from "@imtbl/sdk";
import { immutableZkEvmTestnet } from "../integrations/imx/chain";

export const environment = {
    vip_staking_contract_address: "0xA6Ede313cA6e0cfaB9a049fe25F0a865f80Fc389",
    mee_token_address: "0x516c12448923E28da7F216fab32B69Fcd95e9F6c",
    mee_token_decimals: 18,
    mee_land_nft_address: "0x895753f33e413655DdAfF8E325d02539631c0510",
    subgraph_url: "https://api.studio.thegraph.com/query/16975/medieval-empires-staking-mumba/version/latest",
    mongo_api_base_url: `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.GATSBY_REALM_APP_ID}/endpoint`,
    purchase_usdc_contract_address: "0x939A4d8d8619aF5224d806b2B8DA25786763DcB7",
    purchase_usdt_contract_address: "0x7E1Ef3cE145174f635aDEF900006e64DD98F7f8B",
    purchase_matic_contract_address: "0xd69bA2F0871Fc8460830BfBE50242894818079aD",
    event_rewards_contract_address: "0x9D7B129f43B56463Cec819f30b5E6c019870d70D",
    usdc_token_address: "0x41e94eb019c0762f9bfcf9fb1e58725bfb0e7582",
    usdt_token_address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    burn_address: "0x000000000000000000000000000000000000dEaD",
    wallet_connect_project_id: "68bb307009604d8ba58c5c39fd3137e0",
    imx_resource_nft_address: "0x8Cb475a43633a432633526aeF0b6ED1e5C71bbeB",
    imx_land_nft_address: "0xBff2BbE19B1322a87184e45C929dAf65BF020414",
    imx_genesis_nft_address: "0xeaac4d6fcda83547fb970f8d60aef690c2b2cbf5",
    imx_client_id: "YCfyuvQp7KBHOtpPG9umJdoMz6UDaXnJ",
    imx_environment: config.Environment.SANDBOX,
    imx_publishable_key: "pk_imapik-test-_wpOnVPnl1kUc5VVwUeV",
    imx_chain: immutableZkEvmTestnet,
    chains: [polygonAmoy, immutableZkEvmTestnet],
    blockExplorer: "https://amoy.polygonscan.com",
    contentful_space_id: `${process.env.GATSBY_CONTENTFUL_SPACE_ID}`,
    contentful_environment: "master",
    contentful_access_token: `${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN}`,
    contentful_preview_access_token: `${process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN}`,
    contentful_preview_host: "preview.contentful.com",
    sphere_link: "https://testnet.sphere.market",
    sphere_env: "immutable-testnet"
}   
