import { Link } from 'gatsby';
import { footerLogo, hackenLogo } from '../../images/general';
import React from 'react';
import { constants } from '../../utils/constants';

const Footer = () => {
    return (
        <>
        <section className="bg-footer -mt-1">
          <div className="w-full pt-2">
            <hr className="border-2 border-[#687D8E]" />
            <hr className="pt-1 border-[#3D5060] border" />
          </div>
          <div className="container max-w-[78.3rem] mx-auto py-7 xl:px-0 px-4">
            <div className="lg:flex items-center xl:gap-16 lg:gap-12">
              <div className="">
                <img src={footerLogo} alt="logo" className="sm:max-w-max w-[138px] m-auto"/>
              </div>
              <div className="">
                <ul className="sm:flex sm:flex-wrap lg:mx-auto lg:items-center lg:w-auto xl:space-x-6 sm:space-x-4 hidden">
                  <li className="block mt-4 lg:inline-block lg:mt-0">
                    <Link to={constants.app_routes.community} className="text-sm text-white ">
                      Community
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0">
                    <Link to={constants.help_shift} target="_blank" className="text-sm text-white ">
                      Support
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0">
                    <Link to={constants.app_routes.news} className="text-sm text-white ">
                      News
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0">
                    <Link to={constants.whitePaper} target="_blank" className="text-sm text-white ">
                      Whitepaper
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0">
                    <Link to={constants.app_routes.team} className="text-sm text-white ">
                      Team
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0">
                    <Link to={constants.app_routes.tokenomics} className="text-sm text-white ">
                      Tokenomics
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0">
                    <Link to={constants.app_routes.marketplace.home} className="text-sm text-white ">
                      Marketplace
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0">
                    <Link to={constants.app_routes.privacy_policy} className="text-sm text-white ">
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0">
                    <Link to={constants.app_routes.terms_and_conditions} className="text-sm text-white ">
                      Terms & Conditions
                    </Link>
                  </li>
                </ul>
                <ul className="sm:hidden sm:space-x-4 grid grid-cols-2 mx-auto">
                  <li className="block mt-4 lg:inline-block lg:mt-0 ">
                    <Link to={constants.app_routes.community} className="text-sm text-white ">
                      Community
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0 ">
                    <Link to={constants.app_routes.tokenomics} className="text-sm text-white ">
                    Tokenomics
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0 ">
                    <Link to={constants.app_routes.marketplace.home} className="text-sm text-white ">
                      Marketplace
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0 ">
                    <Link to={constants.app_routes.news} className="text-sm text-white ">
                      News
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0 ">
                    <Link to={constants.app_routes.privacy_policy} className="text-sm text-white ">
                    Privacy Policy
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0 ">
                    <Link to={constants.whitePaper} target='_blank' className="text-sm text-white ">
                      Whitepaper
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0 ">
                    <Link to={constants.app_routes.terms_and_conditions} className="text-sm text-white ">
                    Terms & Conditions
                    </Link>
                  </li>
                  <li className="block mt-4 lg:inline-block lg:mt-0 ">
                    <Link to={constants.app_routes.team} className="text-sm text-white ">
                      Team
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-fulls">
            <hr className="border border-[#35536B]" />
          </div>
          <div className="container max-w-[78.3rem] mx-auto py-7 xl:px-0 px-4">
            <div className="sm:flex items-center xl:gap-16 lg:gap-12">
              <a href={constants.audit_report} rel="noopener noreferrer" target="_blank">
                <img
                  src={hackenLogo}
                  alt="logo"
                  className="sm:mb-0 mb-4 sm:max-w-[445px] h-45 w-[132px] m-auto"
                />
              </a>
              <div className="">
                <p className="text-[11px] leading-4 font-normal text-[#D9E8F6] max-w-7xl sm:w-[96.1%] sm:text-left text-justify ">
                  The existing and planned versions of the Medieval Empires game
                  are a fictionalised depiction of certain historical figures and
                  historical periods. The source historical content on which the
                  game is based, which it does and will depict, are in the public
                  domain. This game and its content have no affiliation or
                  connection to any existing television, radio, or other media
                  content depicting the same or similar historical events or
                  figures. The game and all related content (including promotional
                  content), copyright and other rights property of owners.
                </p>
              </div>
            </div>
          </div>
          <div className="w-fulls">
            <hr className="border border-[#35536B]" />
          </div>
          <div className="container max-w-[78.3rem] mx-auto py-4 xl:px-0 px-4">
            <div className="flex items-center gap-16">
              <p className="text-[#D9E8F6] text-sm leading-8 font-normal">
                Medieval Empires pte. LTD, 2022
              </p>
            </div>
          </div>
        </section>
      </>
    )
}

export default Footer;